import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import {
  seasonChanged,
  selectCurrentSeason,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useCreateSeason,
  useDuplicateSeasons,
  useOverlappedSeasons,
  useUpdateSeason,
} from 'features/rules/seasons/seasons-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { FormButtons } from '../form-buttons/form-buttons';
import { Modal } from 'components/Modal/Modal';
import { OverlappedSeasons } from './overlapped-seasons';
import { PropSeason } from 'graphql/gql-types';
import RouterPromptModal from 'components/RouterPromptModal/RouterPromptModal';
import { RuleTabs } from '../rule-tabs/rule-tabs';
import { SeasonForm } from './season-form';
import { SeasonList } from './season-list';
import { SeasonPriority } from 'types/SeasonTypes';
import { SelectChangeEvent } from '@mui/material';
import { SelectedDays } from 'types/SearchTypes';
import { TwoPaneLayout } from 'components/two-pane-layout/two-pane-layout';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import { today } from 'features/dates/date-helpers';
import { usePropertyContext } from 'context/propertyContext';
import { useSeasons } from 'features/rules/rules-hooks';
import { useUpdateSeasonsPrioritiesMutation } from 'features/rules/seasons/gql/_gen_/prop-seasons.gql';
import { useUser } from 'features/users/context/userContext';
import { z } from 'zod';

const formLabels = labels.add_form;

const SeasonFormSchema = z.object({
  id: z.string().nullable(),
  seasonName: z.string(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
  selectedDays: z.array(z.nativeEnum(SelectedDays)),
  highlightInMyView: z.boolean(),
  priority: z.nativeEnum(SeasonPriority),
});

export type SeasonFormValues = z.infer<typeof SeasonFormSchema>;

export const defaultValues: SeasonFormValues = {
  id: null,
  seasonName: '',
  startDate: null,
  endDate: null,
  selectedDays: [],
  highlightInMyView: true,
  priority: SeasonPriority.Highest,
};

interface LocationState {
  season: PropSeason;
}

export const Seasons = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const dispatch = useAppDispatch();
  const selectedSeason = useAppSelector(selectCurrentSeason);
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { user } = useUser();
  const { createSeason, createSeasonLoading } = useCreateSeason();
  const { updateSeason, updateSeasonLoading } = useUpdateSeason();
  const getOverlappedSeasons = useOverlappedSeasons();
  const getDuplicateSeasons = useDuplicateSeasons();
  const { seasons } = useSeasons();
  const [updateSeasonsPriorities, { loading: updateSeasonPriorityLoading }] =
    useUpdateSeasonsPrioritiesMutation();

  const [tabsEnabled, setTabsEnabled] = useState(false);
  const [overlappedSeasons, setOverlappedSeasons] = useState<PropSeason[]>();
  const [duplicateSeasons, setDuplicateSeasons] = useState<PropSeason[]>();
  const [continueClicked, setContinueClicked] = useState(false);

  if (
    location?.state?.season &&
    selectedSeason?.season_id !== location?.state?.season?.season_id
  ) {
    const season = seasons?.find(
      (s) => s.season_id === location.state.season.season_id
    );
    if (season) dispatch(seasonChanged(season));
  }

  const seasonValidationSchema = useMemo(
    () =>
      Yup.object({
        seasonName: Yup.string().required(formLabels.required),
        startDate: selectedSeason
          ? Yup.date().nullable().required(formLabels.required)
          : Yup.date()
              .nullable()
              .min(today(), formLabels.start_date_err)
              .required(formLabels.required),
        endDate: Yup.date()
          .nullable()
          .required(formLabels.required)
          .min(Yup.ref('startDate'), formLabels.end_date_err),
        selectedDays: Yup.array()
          .nullable()
          .min(1, formLabels.required_day_err),
        highlightInMyView: Yup.boolean(),
        priority: Yup.string(),
      }),
    [selectedSeason]
  );

  const createOrUpdateSeason = async (values: SeasonFormValues) => {
    let success = false;
    if (values.id) {
      const updatedSeason = await updateSeason(values);
      if (updatedSeason) success = true;
    } else {
      const newSeason = await createSeason(values);
      if (newSeason) {
        success = true;
        dispatch(seasonChanged(newSeason));
      }
    }

    if (continueClicked) {
      if (success) {
        // timeout ensures season is updated in redux store before continuing
        setTimeout(() => {
          history.push('/rules/competitors');
        }, 0);
      } else {
        setContinueClicked(false);
      }
    }
  };

  const handleSubmit = (values: SeasonFormValues) => {
    const parsedValues = SeasonFormSchema.parse(values);
    if (!parsedValues.id) {
      const duplicateSeasons = getDuplicateSeasons(parsedValues);
      if (duplicateSeasons) {
        setDuplicateSeasons(duplicateSeasons);
        return;
      }
    }
    const overlappedSeasons = getOverlappedSeasons(parsedValues);
    if (overlappedSeasons) {
      setOverlappedSeasons(overlappedSeasons);
      return;
    }
    createOrUpdateSeason(parsedValues);
  };

  const onSeasonPriorityChange = (
    id: string,
    event: SelectChangeEvent<number>
  ) => {
    const updated = overlappedSeasons?.map((season: PropSeason) => {
      if (season.season_id === id) {
        return { ...season, season_priority: Number(event.target.value) };
      }
      return season;
    });
    setOverlappedSeasons(updated);
  };

  const onOverlappedModalConfirm = async (values: SeasonFormValues) => {
    if (!overlappedSeasons) return;

    const nonOverlappingSeasons: any[] = [];
    const overlappingSeasons: any[] = [];

    for (const season of overlappedSeasons) {
      const overlappedResult = await getOverlappedSeasons({
        id: season.season_id as string,
        seasonName: season.season_name as string,
        startDate: season.season_start as string,
        endDate: season.season_end as string,
        selectedDays: season.selected_days as SelectedDays[],
        priority: season.season_priority as SeasonPriority,
        highlightInMyView: season.highlight_myview as boolean,
      });

      if (
        overlappedResult === undefined &&
        Number(values.priority) !== season.season_priority
      ) {
        nonOverlappingSeasons.push(season);
      } else {
        overlappingSeasons.push(season);
      }
    }
    if (nonOverlappingSeasons.length > 0) {
      await updateSeasonsPriorities({
        variables: {
          input: {
            propertyId,
            seasonsPriorities: nonOverlappingSeasons.map(
              ({ season_name, season_id, season_priority }) => ({
                seasonName: season_name,
                seasonId: season_id,
                seasonPriority: season_priority,
              })
            ),
            updatedBy: user?.login_id,
          },
        },
      });
    }
    if (overlappingSeasons.length === 0) {
      setOverlappedSeasons(undefined);
      createOrUpdateSeason(values);
    } else {
      dispatch(
        alertAdded(
          'error',
          `${overlappingSeasons.length} seasons you are trying to update overlap with existing seasons.`
        )
      );
      setOverlappedSeasons(overlappingSeasons);
    }
  };

  const loading = createSeasonLoading || updateSeasonLoading;

  return (
    <div>
      <RuleTabs tabsEnabled={tabsEnabled} loading={loading} />
      <Formik<SeasonFormValues>
        initialValues={defaultValues}
        validationSchema={seasonValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, validateForm, submitForm }) => (
          <>
            <RouterPromptModal isBlocked={dirty && !continueClicked} />
            <Modal
              isShown={!!duplicateSeasons}
              hide={() => setDuplicateSeasons(undefined)}
              onCancel={() => setDuplicateSeasons(undefined)}
              onConfirm={() => setDuplicateSeasons(undefined)}
              isConfirmationModal
              confirmBtnText={labels.rules.ok}
              messageBody={labels.rules.already_exist_err}
            />
            <Modal
              isShown={!!overlappedSeasons}
              hide={() => setOverlappedSeasons(undefined)}
              onCancel={() => setOverlappedSeasons(undefined)}
              onConfirm={() => onOverlappedModalConfirm(values)}
              isConfirmationModal
              confirmBtnText={labels.common.save}
              loading={updateSeasonPriorityLoading}
            >
              <OverlappedSeasons
                overlappedSeasons={overlappedSeasons!}
                onSeasonPriorityChange={onSeasonPriorityChange}
              />
            </Modal>
            <Form>
              <TwoPaneLayout
                loading={loading}
                LeftSideComponent={<SeasonForm />}
                RightSideComponent={
                  <SeasonList setTabsEnabled={setTabsEnabled} />
                }
              />
              <FormButtons
                loading={loading}
                dirty={dirty}
                onContinueClick={async () => {
                  if (selectedSeason && !dirty) {
                    history.push('/rules/competitors');
                    return;
                  }
                  const isValid =
                    Object.keys(await validateForm()).length === 0;
                  if (isValid) setContinueClicked(true);
                  submitForm();
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
