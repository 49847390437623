import './EventsForm.scss';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  EventFormData,
  EventRecordInput,
  EventResponse,
} from 'graphql/gql-types';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import {
  dateFormatData,
  formatDate,
  formatFormDate,
  today,
} from 'features/dates/date-helpers';
import IconButton from '@mui/material/IconButton';
import {
  selectSelectedEvents,
  setIsEventsFormDirty,
  updateEventsFormValues,
} from 'features/events/redux/events-form-slice';
import { InputDate } from 'components/InputDate/InputDate';
import { NumberInput } from 'components/number-input/number-input';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'features/users/context/userContext';
import { EventCategoryEnum } from 'graphql/gql-types';
import {
  MdDeleteOutline,
  MdOutlineAddBox,
  MdOutlineIndeterminateCheckBox,
  MdOutlineSave,
} from 'react-icons/md';
import { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';

const eventTypeOptions = [
  { label: 'General', value: EventCategoryEnum.general },
  { label: 'Holiday', value: EventCategoryEnum.holiday },
  { label: 'Political', value: EventCategoryEnum.political },
  { label: 'Religious', value: EventCategoryEnum.religious },
  { label: 'School', value: EventCategoryEnum.school },
  { label: 'Sports', value: EventCategoryEnum.sports },
  { label: 'Festival', value: EventCategoryEnum.festival },
];

interface EventFormProps {
  id: string;
  formRef: React.Ref<FormikProps<EventFormData>> | null;
  onFormReset: () => void;
  readonly: boolean;
  formData: EventFormData;
  onSaveClick: () => void;
  onDeleteClick: () => void;
  onRecordDelete: (record: EventRecordInput) => void;
}
const EventsForm = ({
  formRef,
  formData,
  onSaveClick,
  onDeleteClick,
  onRecordDelete,
}: EventFormProps) => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useUser();
  const selectedEvents = useAppSelector(selectSelectedEvents);
  const eventValidationSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string().required(labels.events.validation.required),
      category: Yup.string().required(labels.events.validation.required),
      records: Yup.array().of(
        Yup.object().shape({
          start_date: Yup.date()
            .nullable()
            .required(labels.events.validation.required)
            .min(today(), labels.events.validation.start_date_err),
          end_date: Yup.date()
            .nullable()
            .required(labels.events.validation.required)
            .min(Yup.ref('start_date'), labels.events.validation.end_date_err),
        })
      ),
    });
  }, []);
  return (
    <div className='events'>
      <Formik<EventFormData>
        enableReinitialize={true}
        initialValues={formData}
        innerRef={formRef}
        validationSchema={eventValidationSchema}
        onSubmit={onSaveClick}
      >
        {({
          validateForm,
          values,
          errors,
          setFieldValue,
          dirty,
          touched,
          submitForm,
          ...props
        }: FormikProps<EventFormData>) => {
          dispatch(setIsEventsFormDirty(dirty));

          return (
            <>
              <Form className='events__form' id='events-form'>
                <div className='events__form__row'>
                  <div className='events__form__row-item events__form__row-item-event-name'>
                    <Field name='name'>
                      {(props: FieldProps<EventFormData>) => (
                        <TextField
                          {...props.field}
                          id='name'
                          variant='outlined'
                          placeholder='Event Name'
                          disabled={isReadOnly}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='events__form__row'>
                  <div className='events__form__row-item events__form__row-item-event-type'>
                    <Field name='category'>
                      {(prop: FieldProps<EventCategoryEnum, EventFormData>) => (
                        <Select
                          defaultValue='general'
                          inputProps={{
                            name: 'category',
                            id: 'category',
                          }}
                          label='Event Category'
                          {...prop.field}
                          disabled={isReadOnly}
                          onChange={(event: SelectChangeEvent) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                            dispatch(updateEventsFormValues({ name: value }));
                          }}
                        >
                          {eventTypeOptions.map(({ label, value }) => (
                            <MenuItem key={label} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </div>
                </div>
                <FieldArray name='records'>
                  {({ remove, push }) => (
                    <div className='events__form__row events__form__row-event-records'>
                      <div className='events__form__row-event-record'>
                        {values.records &&
                          values.records.map((value, index) => {
                            return (
                              <div
                                className='events__form__row events__form__row-event-record-date'
                                key={index}
                              >
                                <div className='events__form__row-item'>
                                  <Field name={`records[${index}].start_date`}>
                                    {(
                                      prop: FieldProps<String, EventFormData>
                                    ) => (
                                      <div>
                                        <DatePicker
                                          {...prop.field}
                                          value={
                                            value?.start_date === ''
                                              ? null
                                              : value?.start_date
                                          }
                                          label={labels.events.start_date}
                                          onChange={(date: any) =>
                                            setFieldValue(
                                              `records[${index}].start_date`,
                                              date !== ''
                                                ? formatDate(
                                                    date,
                                                    dateFormatData
                                                  )
                                                : ''
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                prop.meta.touched &&
                                                prop.meta.error
                                              }
                                              margin='normal'
                                              {...params}
                                              {...props}
                                              name={`records[${index}].start_date`}
                                              error={
                                                prop.meta.touched &&
                                                !!prop.meta.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className='events__form__row-item'>
                                  <Field name={`records[${index}].end_date`}>
                                    {(
                                      prop: FieldProps<String, EventFormData>
                                    ) => (
                                      <div>
                                        <DatePicker
                                          {...prop.field}
                                          label={labels.events.end_date}
                                          value={
                                            value?.end_date === ''
                                              ? null
                                              : value?.end_date
                                          }
                                          onChange={(date: any) =>
                                            setFieldValue(
                                              `records[${index}].end_date`,
                                              date !== ''
                                                ? formatDate(
                                                    date,
                                                    dateFormatData
                                                  )
                                                : ''
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                prop.meta.touched &&
                                                prop.meta.error
                                              }
                                              margin='normal'
                                              {...params}
                                              {...props}
                                              name={`records[${index}].end_date`}
                                              error={
                                                prop.meta.touched &&
                                                !!prop.meta.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className='events__form__row-item'>
                                  <Tooltip
                                    title={
                                      labels.events.tooltip.remove_event_record
                                    }
                                    arrow
                                    placement='top'
                                  >
                                    <span>
                                      <IconButton
                                        disableFocusRipple
                                        disableRipple
                                        aria-label='remove-event-record'
                                        onClick={() => {
                                          values.records?.length! > 1 &&
                                            remove(index);
                                          onRecordDelete(value!);
                                        }}
                                        className='events__form-remove-date-btn'
                                        disabled={values.records?.length === 1}
                                      >
                                        <MdOutlineIndeterminateCheckBox
                                          size={22}
                                        />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        className='events__form__row-item-add'
                        style={{ padding: '10px' }}
                      >
                        <Tooltip
                          title={labels.events.tooltip.add_event_record}
                          arrow
                          placement='top'
                        >
                          <span>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              aria-label='add-event-record'
                              onClick={() =>
                                push({
                                  id: '',
                                  start_date: '',
                                  end_date: '',
                                  event_id:
                                    values.event_id !== ''
                                      ? values.event_id
                                      : '',
                                })
                              }
                              className='events__form-add-date-btn'
                            >
                              <MdOutlineAddBox size={22} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className='events__form__row'>
                  <div className='events__form__row-item label'>
                    <InputLabel id='occ_impact' className='label'>
                      {labels.events.occupancy_impact}
                    </InputLabel>
                  </div>
                  <div className='events__form__row-item'>
                    <div className='magnitude'>
                      <Field name='occ_impact'>
                        {(prop: FieldProps<String, EventFormData>) => (
                          <NumberInput
                            {...prop.field}
                            name='occ_impact'
                            disabled={false}
                            showCurrencySymbol={false}
                            allowSigns={true}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              setFieldValue(name, value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className='events__form__row events__form-footer'>
                  <button
                    type='submit'
                    disabled={!dirty}
                    className='events__form-save-btn'
                  >
                    <MdOutlineSave size={22} />
                    {labels.common.save}
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      onDeleteClick();
                    }}
                    className='events__form-delete-btn'
                    disabled={selectedEvents.length === 0}
                  >
                    <MdDeleteOutline size={22} /> {labels.common.delete}
                  </button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default EventsForm;
